import React from 'react'
import { Link } from "gatsby"

import LogoutButton from './logoutButton'

const UserMenu = () => {

  return (
    <div className="user-menu">
          <div className="flex z-10">
            <Link to="/account" className="btn-user-menu mr-2 md:mr-4" activeClassName="active">Dashboard</Link>
            <Link to="/account/orders" className="btn-user-menu mr-2 md:mr-4" activeClassName="active">Orders</Link>
            <Link to="/account/profile" className="btn-user-menu" activeClassName="active">Profile</Link>
          </div>
          <div className="btn-user-menu z-10"><LogoutButton/></div>
        </div>
  )
}

export default UserMenu