import React, { useEffect } from 'react';

// Hooks & Querys & Context
import { useSiteState } from '../../lib/siteContext';

// Components
import Layout from '../../components/layout';
import Seo from '../../components/global/seo';
import UserMenu from '../../components/user/userMenu';

const Profile = () => {

    const { 
        setFooterHidden, 
        setHeaderDark, 
        setFooterTheme, 
        user 
      } = useSiteState();

    useEffect(() => {    
        document.documentElement.setAttribute('data-theme', `theme-black`);
        document.documentElement.style.setProperty(`--doc-bg`, '#141414');                  
        setHeaderDark(false)    
        setFooterHidden(false)
        setFooterTheme('theme-none')
        user && console.log(user)
    }, [user, setFooterHidden, setFooterTheme, setHeaderDark]);    

  return (
    <Layout>      
      <Seo title="Orders" metaTitle="Orders" hidden={true} />
      <div className="block w-full h-24 lg:h-header lg:mb-20"></div>
      <UserMenu />
      <section className="grid grid-cols-1 md:grid-cols-2 md:items-start gap-8 lg:gap-gutter grid-flow-row py-12 lg:py-20 md:min-h-user">        
        <div className="px-4 text-lg">Orders</div>
        <ul className="grid grid-cols-1 gap-6 px-4">          
        </ul>
      </section>      
    </Layout>
  )
}

export default Profile