import React from 'react'
import { navigate } from "gatsby"
import { useSiteState } from '../../lib/siteContext';

const LogoutButton = () => {

  const { setUser } = useSiteState();
      
    // function handleSubmit = async event =>  {  
    //     event.preventDefault()     
    //     const response = fetch(`/api/logout`, {
    //         method: `POST`,
    //         headers: {
    //           "content-type": "application/json",
    //         },
    //         body:  JSON.stringify({}),
    //       })      
    //       .then((response) => response.json())
    //       .then((data) => {
    //         console.log(data);
    //         if (data.success === false) {                
    //             return
    //         }
    //         if (data.success === true) {
    //             localStorage.setItem('user:account', null);                                
    //               navigate(`/`)                
    //         }
    //       });
    // }

    function handleSubmit(event)  {  
          event.preventDefault()    
          setUser(null)
          localStorage.removeItem('user:account')
          localStorage.removeItem('projectEmail')
          navigate(`/`)                
      }

  return (
    <form method="post" onSubmit={event => {handleSubmit(event)}}>          
    <button type="submit" className="appearance-none">Log out</button>                    
  </form>  
  )
}

export default LogoutButton